<template>
    <p class="h5 mt-4 font-weight-bold">
        {{ __('TITLE.COMPANY') }}
    </p>
    <div>
        <sub-menu-top-bar
            :menu-items="menuItems"
        />
        
        <div class="card">
            <div class="card-body">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
import __ from '../../utils/translator.js';

import SubMenuTopBar from '../../components/sub-menu-top-bar.vue';

export default {
    components: {
        SubMenuTopBar
    },

    data() {
        return {
            enabled_general: 0,
            enabled_facility: 0
        }
    },

    computed: {
        menuItems() {
            return [
                {
                    title: __('MENUBAR.GENERAL_DATA'),
                    onclick: (ev) => this.goto(ev, '/company/general-data'),
                    selected: this.enabled_general,
                    mobileIconClass: 'fas fa-building'
                },
                {
                    title: __('MENUBAR.FACILITIES'),
                    onclick: (ev) => this.goto(ev, '/company/facilities'),
                    selected: this.enabled_facility,
                    mobileIconClass: 'fas fa-industry'
                }
            ]
        }
    },
    
    mounted() {
        this.setEnabledMenuItem();
    },

    beforeUpdate() {
        this.enabled_general = 0;
        this.enabled_facility = 0;
        this.setEnabledMenuItem();
    },

    methods: {
        goto(ev, path) { //Programamtic navigation
            ev.preventDefault();
            this.$router.push({ path });
        },

        setEnabledMenuItem() {
            //Set "current" class in the current menu section
            /* SET CURRENT MENU CLASS */
            switch (this.$route.path) {
                case '/company':
                case '/company/general-data':
                    this.enabled_general = 1;
                    break;
                case '/company/facilities':
                    this.enabled_facility = 1;
                    break;
            }
        },

        __ //TRANSLATOR
    }
};
</script>